<template>
  <div class="ui black inverted attached segment">
    <div class="ui container">
      <div class="ui middle aligned centered stackable grid">
        <div class="two wide column">
          <router-link :to="{ name: 'Accueil' }">
            <img
              class="ui centered small image"
              src="@/assets/images/logo_r.png"
              width="120px"
              alt="Logo École de Musique Muller"
            />
          </router-link>
        </div>
        <div class="eight wide column">
          <router-link :to="{ name: 'Accueil' }">
            <div class="ui inverted huge header" id="title">
              École de Musique Muller
            </div>
          </router-link>
        </div>
      </div>
    </div>
  </div>
  <div class="ui inverted red attached stackable menu">
    <div class="ui container">
      <router-link :to="{ name: 'Accueil' }" class="item">Accueil</router-link>
      <router-link :to="{ name: 'Cours' }" class="item">Cours</router-link>
      <router-link :to="{ name: 'Ecoles' }" class="item">Écoles</router-link>
      <div class="ui inverted red dropdown link item">
        Concours <i class="dropdown icon"></i>
        <div class="menu">
          <router-link :to="{ name: 'ConcoursAMF' }" class="item"
            >Présentation</router-link
          >
          <router-link :to="{ name: 'EliminatoiresAMF2022' }" class="item"
            >Résultats Éliminatoires 2022</router-link
          >
          <router-link :to="{ name: 'FinalesAMF2021' }" class="item"
            >Résultats Finales 2021</router-link
          >
          <router-link :to="{ name: 'LaureatsAMF' }" class="item"
            >Tous nos Lauréats AMF</router-link
          >
        </div>
      </div>
      <router-link :to="{ name: 'ContactezNous' }" class="item"
        >Contactez-nous</router-link
      >
    </div>
  </div>
</template>
