<template>
  <div class="ui padded attached segment" id="content">
    <div class="ui stackable grid">
      <div class="row">
        <div class="eight wide column">
          <img
            src="../assets/images/accueil/cours-de-musique.svg"
            class="ui fluid image"
          />
        </div>
        <div class="eight wide column">
          <h1 class="ui header">Cours de musique</h1>
          <p>
            Venez découvrir, apprendre et jouer sur votre instrument favori
            <strong>à tout âge</strong> ! Nous vous proposerons
            <strong>méthodes et morceaux adaptés</strong> à vos goûts et votre
            niveau. Pour ceux qui le souhaitent, goûtez au
            <strong>plaisir</strong> de participer à un
            <strong>concours</strong>.
          </p>
        </div>
      </div>
      <div class="row">
        <div class="three wide column">
          <img
            src="../assets/images/accueil/instruments.svg"
            class="ui fluid image"
          />
        </div>
        <div class="five wide column">
          <h1 class="ui header">Plusieurs instruments</h1>
          <p>
            Nous vous proposons des cours pour près d’une
            <strong>dizaine d’instruments différents</strong>, et ce
            <strong>quelque soit votre âge et votre niveau</strong>, en
            <strong>cours collectif ou individuel</strong>.
          </p>
        </div>
        <div class="three wide column">
          <img
            src="../assets/images/accueil/carte.svg"
            class="ui fluid image"
          />
        </div>
        <div class="five wide column">
          <h1 class="ui header">Plusieurs villes</h1>
          <p>
            Vous pouvez nous retrouver à <strong>Molsheim</strong>, ainsi qu'à
            <strong>Wisches</strong>.
          </p>
        </div>
      </div>
      <div class="ui divider"></div>
      <div class="centered row">
        <div class="ten wide column">
          <h1 class="ui header center aligned"><strong>Actualités</strong></h1>
          <router-link
            :to="{ name: 'FinalesAMF2021' }"
            style="color: rgba(0, 0, 0, 0.87)"
          >
            <div
              style="background-color: #ddd; padding: 10px; border-radius: 10px"
            >
              <h2 style="font-size: 18px">Finales 2021 à Chaville</h2>
              <p>
                Le 25 octobre 2021 se sont déroulées les finales du Trophée
                National. Plusieurs élèves de l'école y ont participé, après
                avoir obtenu de (très) bons résultats au concours régional en
                juin. Félicitations particulières à 4 d'entres eux. En piano 3
                élèves se sont distinguées : BUCCIACCHIO Emma, HABER Clémence
                ont remporté le 1er prix Trophée National, également LORBER
                Marie qui a eu en plus les félicitations du jury. Autre très
                belle performance, ZERARA Samir a gagné 2 trophées cette année,
                dont un avec félicitations du jury, en catégorie supérieur 2 à
                la guitare classique et à la guitare électrique, ils viennent
                compléter ceux obtenus en 2017 et 2018. Un grand merci à eux 4
                et à tous les autres élèves qui ont participé à ces finales 2021
                pour leur beau travail !
              </p>
              <button class="ui red button">Voir les résultats</button>
            </div>
          </router-link>
        </div>
      </div>
    </div>
  </div>
</template>
