import { createRouter, createWebHistory } from "vue-router";
import HomeView from "../views/HomeView.vue";

const routes = [
  {
    path: "/",
    name: "Accueil",
    component: HomeView,
    meta: {
      title: "Jouez sur votre instrument favori",
    },
  },
  {
    path: "/cours",
    name: "Cours",
    component: () =>
      import(/* webpackChunkName: "cours" */ "../views/ClassesView.vue"),
    meta: {
      title: "Nos cours de musique",
    },
  },
  {
    path: "/ecoles",
    name: "Ecoles",
    component: () =>
      import(/* webpackChunkName: "cours" */ "../views/SchoolsView.vue"),
    meta: {
      title: "Nos écoles de musique",
    },
  },
  {
    path: "/concours-amf",
    name: "ConcoursAMF",
    component: () =>
      import(
        /* webpackChunkName: "cours" */ "../views/Contests/PresentationView.vue"
      ),
    meta: {
      title: "Concours AMF",
    },
  },
  {
    path: "/resultats-eliminatoires-amf-2022",
    name: "EliminatoiresAMF2022",
    component: () =>
      import(
        /* webpackChunkName: "cours" */ "../views/Contests/Playoffs2022View.vue"
      ),
    meta: {
      title: "Résultats Éliminatoires AMF 2022",
    },
  },
  {
    path: "/resultats-finales-amf-2021",
    name: "FinalesAMF2021",
    component: () =>
      import(
        /* webpackChunkName: "cours" */ "../views/Contests/Finals2021View.vue"
      ),
    meta: {
      title: "Résultats Finales AMF 2021",
    },
  },
  {
    path: "/laureats-amf",
    name: "LaureatsAMF",
    component: () =>
      import(
        /* webpackChunkName: "cours" */ "../views/Contests/WinnersView.vue"
      ),
    meta: {
      title: "Tous nos Lauréats du Concours AMF",
    },
  },
  {
    path: "/contactez-nous",
    name: "ContactezNous",
    component: () =>
      import(/* webpackChunkName: "cours" */ "../views/ContactUsView.vue"),
    meta: {
      title: "Contactez-nous",
    },
  },
  {
    path: "/mentions-legales",
    name: "MentionsLegales",
    component: () =>
      import(/* webpackChunkName: "cours" */ "../views/LegalView.vue"),
    meta: {
      title: "Mentions légales",
    },
  },
  {
    path: "/page-non-trouvee",
    name: "PageNonTrouvee",
    component: () =>
      import(/* webpackChunkName: "cours" */ "../views/NotFoundView.vue"),
    meta: {
      title: "Page non trouvée",
    },
  },
  {
    path: "/:catchAll(.*)",
    redirect: "/page-non-trouvee",
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  linkActiveClass: "active",
});

router.beforeEach((to, _from, next) => {
  document.title = `${to.meta.title} - École de Musique Muller`;
  next();
});

export default router;
